
import Vue from 'vue';
import { mapGetters } from 'vuex';

import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import Account from 'Entities/privatePresenter/Account';

type NoData = Record<string, never>;

interface Props {
    spotFees?: number;
    futuresFees?: number;
    transferFees?: number;
    dexFees?: number;
}

interface Methods {
    handleNumber: (data: number) => string;
}

interface Computed {
    quotationAssetCharacter: string;
    quotationAssetSymbol: string;
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    precision: number;
    totalFees: number;
}

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: {
        AccountColorMarker,
    },
    props: {
        spotFees: {
            type: Number,
            default: 0,
        },
        futuresFees: {
            type: Number,
            default: 0,
        },
        transferFees: {
            type: Number,
            default: 0,
        },
        dexFees: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        totalFees() {
            return (this.spotFees ?? 0) + (this.futuresFees ?? 0) + (this.transferFees ?? 0) + (this.dexFees ?? 0);
        },
    },
    methods: {
        handleNumber(num) {
            if (!num && num !== 0) {
                return 'N/A';
            }
            return num.toFixed(this.precision).noExponents().getSeparatedDigits();
        },
    },
});
