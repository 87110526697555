
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { getData, getNewPage, SET_UI, TabType } from 'Store/v2/Statements';
import Account from 'Entities/privatePresenter/Account';
import SpotTrade from 'Entities/statementsPresenter/SpotTrade';
import FuturesTrade from 'Lib/entities/statementsPresenter/FuturesTrade';
import FuturesFunding from 'Entities/statementsPresenter/FuturesFunding';
import DexTransaction from 'Entities/statementsPresenter/DexTransaction';
import Transfer from 'Entities/statementsPresenter/Transfer';
import Guid from 'UI/Guid.vue';
import AssetValue from 'UI/AssetValue.vue';
import Pagination from 'Control/Pagination.vue';

interface Props {
    range: any;
}

interface Data {
    theme: any;
    tableTabsVariants: any;
    currentPage: number;
}

interface Methods {
    setTabType: (data: any) => void;
    tableDateFormatter: (date: string) => string;
    noExponentialNumber: (data: number) => string;
}

interface Computed {
    isThemeDark: boolean;
    activeAccount: Account;
    activeAccountId: string;
    quotationAsset: string;
    activeAccountColor: string;
    totalPages: number;
    tabType: TabType;
    spotTrades: SpotTrade[];
    futuresTrades: FuturesTrade[];
    futuresFundings: FuturesFunding[];
    swaps: DexTransaction[];
    liquidityPools: DexTransaction[];
    transfers: Transfer[];

}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        AccountColorMarker,
        Guid,
        AssetValue,
        Pagination,
    },
    props: {
        range: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            theme,
            tableTabsVariants: TabType,
            currentPage: 1,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
            activeAccount: 'Accounts/activeAccount',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        quotationAsset() {
            return this.$store.state.Assets.quotationAssetSymbol;
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        totalPages() {
            const totalPages = this.$store.state.Statements.ui.transactionsPagination.totalPage;
            return totalPages === -1 ? 0 : totalPages as number;
        },
        tabType() {
            return this.$store.state.Statements.ui.dataType;
        },
        spotTrades() {
            return this.$store.state.Statements.spotTrades;
        },
        futuresTrades() {
            return this.$store.state.Statements.futuresTrades;
        },
        futuresFundings() {
            return this.$store.state.Statements.futuresFundings;
        },
        swaps() {
            return this.$store.state.Statements.swaps;
        },
        liquidityPools() {
            return this.$store.state.Statements.liquidityPools;
        },
        transfers() {
            return this.$store.state.Statements.transfers;
        },
    },
    methods: {
        setTabType(type) {
            this.$store.commit(SET_UI({ dataType: type }));
        },
        tableDateFormatter(date) {
            const startDate = new Date(date).toDateString();
            const dateComponentsArray = startDate.split(' ');
            const month = dateComponentsArray[1];
            const day = dateComponentsArray[2];
            const year = dateComponentsArray[3];
            const time = new Date(date).toTimeString().split(' ')[0];
            return `${day} ${month} ${year}, ${time}`;
        },
        noExponentialNumber(num) {
            let result = '0.';
            const initialString = String(num);
            const firstPart = initialString.split('e')[0];
            const secondPart = initialString.split('e')[1];
            if (!secondPart) {
                return String(num);
            }
            const integerPart = firstPart.split('.')[0];
            const floatPart = firstPart.split('.')[1];
            if (floatPart) {
                const zeros = (-1 * Number(secondPart)) - integerPart.length;
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart + floatPart;
            } else {
                const zeros = (-1 * Number(secondPart));
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart;
            }
            return result;
        },
    },
    watch: {
        async range() {
            await this.$store.dispatch(getData((this as any).range));
        },
        tabType() {
            this.currentPage = 1;
            this.$store.dispatch(getData((this as any).range));
        },
        activeAccountId() {
            if (this.activeAccountId) {
                this.currentPage = 1;
                this.$store.dispatch(getData((this as any).range));
            }
        },
        async currentPage(val) {
            await this.$store.dispatch(getNewPage({ ...(this as any).range, page: val }));
        },
    },
});
