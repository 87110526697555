
import Vue from 'vue';

import Icon from 'UI/Icon.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';

interface Props {
    guid: string;
    isShort?: boolean;
    isLink?: boolean;
    link?: string;
    copiedValueName?: string;
}

interface Methods {
    onClick: () => void;
    copy: () => void;
    goTo: () => void;
}

interface Computed {
    text: string;
}

export default Vue.extend<any, Methods, Computed, Props>({
    components: { Icon },
    props: {
        guid: {
            type: String,
            required: true,
        },
        isShort: {
            type: Boolean,
            default: false,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: '',
        },
        copiedValueName: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        text() {
            if (!this.guid) {
                return '...';
            }
            return this.isShort ? `${this.guid.substring(0, 4)}...${this.guid.substring(this.guid.length - 4, this.guid.length)}` : this.guid;
        },
    },
    methods: {
        onClick() {
            if (this.isLink) {
                this.goTo();
                return;
            }
            this.copy();
        },
        copy() {
            copyTextWithPermissions(this.guid, this.$store.dispatch, this.copiedValueName);
        },
        goTo() {
            window.open(this.link);
        },
    },
});
