import Vue from 'vue';

export interface NumberFormatterMethods {
    toFixedFormat: (num: number, digits: number) => string;
    toFixedSeparatedDigits: (num: number, firstDigits: number, secondDigits: number) => string;
    toFixedIntSeparatedDigits: (num: number, digits: number) => string;
    toFixedFraction: (num: number, digits: number) => string;
    truncateNumber: (num: number, precision: number) => number;
    noExponentialNumber: (num: number) => string;
}

export default Vue.extend<any, any, NumberFormatterMethods, any>({
    methods: {
        toFixedFormat(num: number, digits: number): string {
            return Number(num.toFixed(digits)).nFormat();
        },
        toFixedSeparatedDigits(num: number, firstDigits: number, secondDigits: number): string {
            return Number(num.toFixed(firstDigits)).toFixed(secondDigits).getSeparatedDigits();
        },
        toFixedIntSeparatedDigits(num: number, digits: number): string {
            return Number(num.toFixed(digits)).getInt().getSeparatedDigits();
        },
        toFixedFraction(num: number, digits: number): string {
            return Number(num.toFixed(digits)).fraction();
        },
        truncateNumber(num: number, precision: number): number {
            const noExponentialValue = this.noExponentialNumber(num);
            const tmpArr = noExponentialValue.split('.');
            const floatPart = tmpArr[1];
            if (floatPart) {
                const newFloatPart = floatPart.slice(0, precision);
                return Number([tmpArr[0], newFloatPart].join('.'));
            }
            return num;
        },
        noExponentialNumber(num: number | string): string {
            let result = '0.';
            const initialString = String(num);
            const firstPart = initialString.split('e')[0];
            const secondPart = initialString.split('e')[1];
            if (!secondPart) {
                return String(num);
            }
            const integerPart = firstPart.split('.')[0];
            const floatPart = firstPart.split('.')[1];
            if (floatPart) {
                const zeros = (-1 * Number(secondPart)) - integerPart.length;
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart + floatPart;
            } else {
                const zeros = (-1 * Number(secondPart));
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart;
            }
            return result;
        },
    },
});
