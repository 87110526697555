
import Vue from 'vue';

import Icon from 'UI/Icon.vue';

/*
* emit
* change: () => number;
* */

interface Props {
    page?: number;
    pagesQuantity?: number;
    labelsQuantity?: number;
    disabled?: boolean;
}

interface Methods {
    setActivePage: (data: number) => void;
    showPageLabel: (data: number) => boolean;
    setPreviousPage: () => void;
    setNextPage: () => void;
}

interface Computed {
    showStartSeparator: boolean;
    showEndSeparator: boolean;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: { Icon },
    props: {
        page: {
            type: Number,
            default: 1,
        },
        pagesQuantity: {
            type: Number,
            default: 1,
        },
        labelsQuantity: {
            type: Number,
            default: 3,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showStartSeparator() {
            return this.page! >= this.labelsQuantity! + 2;
        },
        showEndSeparator() {
            return this.page! <= this.pagesQuantity! - (this.labelsQuantity! + 1);
        },
    },
    methods: {
        setActivePage(pageNumber) {
            if (pageNumber !== this.page) {
                this.$emit('change', pageNumber);
            }
        },
        showPageLabel(pageNumber) {
            return pageNumber - this.page! < this.labelsQuantity! && this.page! - pageNumber < this.labelsQuantity!;
        },
        setPreviousPage() {
            if (this.page! > 1) {
                this.setActivePage(this.page! - 1);
            }
        },
        setNextPage() {
            if (this.page! < this.pagesQuantity!) {
                this.setActivePage(this.page! + 1);
            }
        },
    },
});
