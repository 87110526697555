<template>
    <div class="page-layout">

        <slot name="mobile-view"
              v-if="showMobileView"
        >
        </slot>

        <slot name="desktop-view"
              v-else-if="showDesktopView"
        >
        </slot>

    </div>
</template>

<script>
export default {
    name: 'PageLayout',
    data() {
        return {
            windowWidth: window.innerWidth,
            breakpoints: {
                mobile: 800,
            },
        };
    },
    computed: {
        showMobileView() {
            return this.windowWidth <= this.breakpoints.mobile;
        },
        showDesktopView() {
            return this.windowWidth > this.breakpoints.mobile;
        },
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
    created() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    destroyed() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
};
</script>
