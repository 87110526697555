
import Vue from 'vue';
import { mapGetters } from 'vuex';

import numberFormater from 'Mixins/numberFormater';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import { StatementsState, init, getData } from 'Store/v2/Statements';
import { SET_LOADING_ON } from 'Store/v2/Preloader';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import { Account } from 'Models/accounts';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Table from 'Components/Statement/components/Table.vue';
import Balance from 'Components/Statement/components/Balance.vue';
import Fees from 'Components/Statement/components/Fees.vue';
import Performance from 'Components/Statement/components/Performance.vue';
import Header from 'Components/Statement/components/Header.vue';

interface Data {
    currentBreakpoint: string,
    range: { start: Date, end: Date },
    isStatementHeaderVisible: boolean;
}
type Computed = {
    layout: { get: () => any, set: (value: any) => any };
    cardsData: StatementsState['cardsData'];
    activeAccountId: string;
    quotationAsset: string; // 'USD' | 'BTC' | 'EUR' | 'ETH' | undefined;
    hasStatementsAccess: boolean;
    activeAccount: Account;
    isThemeDark: boolean;
    currentUser: InternalUserResponse | undefined;
    isMainLoaderOn: boolean;
    isStatementsLoaderOn: boolean;
    layoutMarginTop: string;
};

type Methods = {
    breakpointChangedEvent: any,
    scrollListener: () => void;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        PageLayout,
        Table,
        ModuleBlocker,
        Balance,
        Fees,
        Performance,
        Header,
    },
    mixins: [
        numberFormater,
        layoutNameResolver,
    ],
    data() {
        const now = new Date();
        const monthAgo = new Date();
        monthAgo.setMonth(now.getMonth() - 1);
        return {
            currentBreakpoint: 'lg',
            range: { start: monthAgo, end: now },
            isStatementHeaderVisible: true,
        };
    },
    async created() {
        try {
            await this.$store.dispatch(init(this.range));
        } catch {
            // code crushed because of network error
        }
        try {
            await this.$store.dispatch(getData(this.range));
        } catch {
            // code crushed because of network error
        }
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        layoutMarginTop() {
            return `${document.getElementById('statementHeader')?.getBoundingClientRect().height ?? 0}px`;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        cardsData() {
            return this.$store.state.Statements.cardsData;
        },
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.STATEMENTS, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', { page: gridPages.STATEMENTS, grid, breakpoint: this.currentBreakpoint });
            },
        },
        activeAccountId() {
            return this.$store.getters['Accounts/activeAccountID'];
        },
        quotationAsset() {
            return this.$store.state.Assets.quotationAssetSymbol;
        },
        hasStatementsAccess() {
            if (this.activeAccount) {
                return this.activeAccount.policies.includes('statements');
            }
            return true;
        },
        isMainLoaderOn() {
            return this.$store.state.Preloader.isLoading;
        },
        isStatementsLoaderOn() {
            return this.$store.state.Statements.ui.isLoading;
        },
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
        },
        scrollListener() {
            this.isStatementHeaderVisible = document.querySelector('body')!.scrollTop < 40;
            if (!this.isStatementHeaderVisible) {
                (this.$refs.statementHeader as any).style.left = `${document.querySelector('aside')!.getBoundingClientRect().width + 14}px`;
            }
        },
    },
    mounted() {
        document.querySelector('body')!.addEventListener('scroll', this.scrollListener);
    },
    beforeDestroy() {
        document.querySelector('body')!.removeEventListener('scroll', this.scrollListener);
    },
    watch: {
        async range() {
            await this.$store.dispatch(init(this.range));
        },
        activeAccountId() {
            if (this.activeAccountId) {
                this.$store.dispatch(init(this.range));
            }
        },
        isMainLoaderOn(value) {
            if (!value && this.isStatementsLoaderOn) {
                this.$store.commit(SET_LOADING_ON(undefined));
            }
        },
    },
});
