import Vue from 'vue';

type Methods = {
    getLayoutElementByName: (l: any, name: string) => any;
}

type Empty = Record<string, never>;

export default Vue.extend<Empty, Methods, Empty, Empty>({
    methods: {
        getLayoutElementByName(layout: any, name: string) {
            const element = layout.find((el) => el.name === name);
            return element || {};
        },
    },
});
