
import Vue from 'vue';
import { mapGetters } from 'vuex';

import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import Account from 'Entities/privatePresenter/Account';

type NoData = Record<string, never>;

interface Props {
    spotVolume?: number;
    futuresVolume?: number;
    fundingsVolume?: number;
    dexVolume?: number;
    spotOrders?: number;
    spotTrades?: number;
    futuresOrders?: number;
    futuresTrades?: number;
    futuresLiquidations?: number;
    dexSwaps?: number;
    liquidations?: number;
}

interface Methods {
    handleNumber: (data: number) => string;
}

interface Computed {
    quotationAssetCharacter: string;
    quotationAssetSymbol: string;
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    precision: number;
}

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: {
        AccountColorMarker,
    },
    props: {
        spotVolume: {
            type: Number,
            default: 0,
        },
        futuresVolume: {
            type: Number,
            default: 0,
        },
        fundingsVolume: {
            type: Number,
            default: 0,
        },
        dexVolume: {
            type: Number,
            default: 0,
        },
        spotOrders: {
            type: Number,
            default: 0,
        },
        spotTrades: {
            type: Number,
            default: 0,
        },
        futuresOrders: {
            type: Number,
            default: 0,
        },
        futuresTrades: {
            type: Number,
            default: 0,
        },
        futuresLiquidations: {
            type: Number,
            default: 0,
        },
        dexSwaps: {
            type: Number,
            default: 0,
        },
        liquidations: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        handleNumber(num) {
            if (!num && num !== 0) {
                return 'N/A';
            }
            return num.toFixed(this.precision).noExponents().getSeparatedDigits();
        },
    },
});
